// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-store-index-js": () => import("./../../../src/pages/store/index.js" /* webpackChunkName: "component---src-pages-store-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-connect-page-js": () => import("./../../../src/templates/connect-page.js" /* webpackChunkName: "component---src-templates-connect-page-js" */),
  "component---src-templates-disclaimer-page-js": () => import("./../../../src/templates/disclaimer-page.js" /* webpackChunkName: "component---src-templates-disclaimer-page-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-healing-sessions-page-js": () => import("./../../../src/templates/healing-sessions-page.js" /* webpackChunkName: "component---src-templates-healing-sessions-page-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-music-page-js": () => import("./../../../src/templates/music-page.js" /* webpackChunkName: "component---src-templates-music-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

